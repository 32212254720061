import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Device } from '../../types'
import * as actions from './actions'

export type State = { [id: string]: Device }

export const initialState: State = {}

export default reducerWithInitialState<State>(initialState)
  .case(actions.receiveDevice, (state, device) => {
    return {
      ...state,
      [device.id]: device,
    }
  })
  .case(actions.updateDevice, (state, device) => {
    return {
      ...state,
      [device.id]: {
        ...state[device.id],
        ...device,
      },
    }
  })
