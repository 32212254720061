// @flow
import { connect } from 'react-redux'
import UserForm from '../../components/UserForm'
import { getUser } from '../../store/Auth/selectors'
import { State as RootState } from '../../types'
// import * as selectors from './selectors'

const ms = (state: RootState) => ({
  user: getUser(state),
})

const conn = connect(
  ms,
  {},
)

export default conn(UserForm)
