import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { GlobalStyle } from './components'
import './config/init'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import configureStore from './store'
import theme from './theme'

const { store, persistor } = configureStore()

const root = document.getElementById('root')

if (root) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div>
          <GlobalStyle />
          <CssBaseline />
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </div>
      </PersistGate>
    </Provider>,
    root,
  )
}

serviceWorker.register()
