import * as React from 'react'
import { connect } from 'react-redux'

import moment, { Moment } from 'moment'
import {
  AxisDomain,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import * as selectors from '../../store/DeviceLogsByDid/selectors'
import { DeviceLog, State as RootState } from '../../types'

type Field = { dataKey: string; name: string; color: string }

type Props = {
  logs: DeviceLog[]
  fields: Field[]
  domain?: [AxisDomain, AxisDomain]
}

const startOf5min = (m: Moment): Moment => {
  const minute = m.minute()

  return m.set({ minute: minute - (minute % 5), second: 0 })
}

const makeJustTimeTicks = (logs: DeviceLog[]): number[] => {
  if (logs.length === 0) {
    return []
  }
  const m = startOf5min(moment(logs[0].timestamp))
  const last = moment(logs[logs.length - 1].timestamp)
  const ticks = [] as number[]

  while (m.isBefore(last)) {
    ticks.push(m.unix() * 1000)
    m.add(5, 'minute')
  }
  return ticks
}

const DeviceLogs: React.SFC<Props> = props => {
  const { logs } = props

  if (logs.length === 0) {
    return <span>ログがありません。</span>
  }
  const ticks = makeJustTimeTicks(logs)

  return (
    <ResponsiveContainer aspect={2.618} width="100%">
      <LineChart data={logs} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
        <XAxis
          ticks={ticks}
          type={'number'}
          domain={props.domain}
          dataKey="timestamp"
          tickFormatter={v => moment(v).format('HH:mm:ss')}
        />
        <YAxis domain={['dataMin - 10', 'dataMax + 10']} />
        <Tooltip labelFormatter={v => moment(v).format('HH:mm:ss')} />
        <Legend />
        <CartesianGrid stroke="#f5f5f5" />
        {props.fields.map((field, i) => (
          <Line
            key={i}
            type="monotone"
            yAxisId={0}
            isAnimationActive={false}
            {...field}
            stroke={field.color}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

type OProps = {
  deviceId: string
  fields: Field[]
}

const ms = (state: RootState, op: OProps) => ({
  logs: selectors.getDeviceLogs(state, op.deviceId),
})

const conn = connect(
  ms,
  {},
)

export default conn(DeviceLogs)
