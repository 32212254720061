import { actionCreatorFactory } from 'typescript-fsa'
import { DeviceLog, DeviceStateLog } from '../../types'

const actionCreator = actionCreatorFactory()

export const receiveDeviceLog = actionCreator<{
  deviceId: string
  log: DeviceLog
  filterTime: number
}>('receiveDeviceLog')
export const receiveDeviceStateLog = actionCreator<{
  deviceId: string
  stateLog: DeviceStateLog
  filterTime: number
}>('receiveDeviceStateLog')
