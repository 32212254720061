import { Grid , Typography , Card } from '@material-ui/core'


import * as React from 'react'
import { connect } from 'react-redux'
import { AxisDomain } from 'recharts'
import * as selectors from '../../store/DeviceById/selectors'
import { getTimestamp } from '../../store/System/selectors'
import { Device, State as RootState } from '../../types'
import DeviceLogs from '../DeviceLogs'
import DeviceStateLogs from '../DeviceStateLogs'
import LogExportForm from './LogExportForm'

type Props = {
  device: Device | undefined
  now: number
}

const DeviceComponent: React.SFC<Props> = props => {
  const { device, now } = props

  if (!device) {
    return <Typography>loading</Typography>
  }

  const domain: [AxisDomain, AxisDomain] = [now - 1800000, now]

  return (
    <div>
      <Card>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5">{device.label}</Typography>
          <Typography>&lt;{device.id}&gt;</Typography>
        </div>
        <Typography>{device.timestampStr}</Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h5">状態</Typography>
                <Typography variant="h4">
                  {device.landingStr ? device.landingStr : '---'}
                </Typography>
              </div>
              <LogExportForm device={device} />
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={4} style={{ marginTop: '10px' }}>
            <Typography variant="h5">心拍数</Typography>
            <div style={{ display: 'flex' }}>
              <Typography variant="h4">{device.heartRate}</Typography>
              <Typography variant="h6" style={{ lineHeight: '2.2em' }}>
                (bpm)
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={4} style={{ marginTop: '10px' }}>
            <Typography variant="h5">腹側呼吸数</Typography>
            <div style={{ display: 'flex' }}>
              <Typography variant="h4">{device.respirationAbdRate}</Typography>
              <Typography variant="h6" style={{ lineHeight: '2.2em' }}>
                (回/分)
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={4} style={{ marginTop: '10px' }}>
            <Typography variant="h5">胸側呼吸数</Typography>
            <div style={{ display: 'flex' }}>
              <Typography variant="h4">
                {device.respirationChestRate}
              </Typography>
              <Typography variant="h6" style={{ lineHeight: '2.2em' }}>
                (回/分)
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div style={{ width: '100%' }}>
          <Typography variant="h5">30分間の状態グラフ</Typography>
          <DeviceStateLogs domain={domain} deviceId={device.id} />
        </div>
        <div style={{ width: '100%' }}>
          <Typography variant="h5">30分間の呼吸数グラフ</Typography>
          <DeviceLogs
            deviceId={device.id}
            domain={domain}
            fields={[
              {
                dataKey: 'respirationAbdRate',
                color: '#0288d1',
                name: '腹側',
              },
              {
                dataKey: 'respirationChestRate',
                color: '#00897b',
                name: '胸側',
              },
            ]}
          />
          <Typography variant="h5">30分間の心拍数グラフ</Typography>
          <DeviceLogs
            deviceId={device.id}
            domain={domain}
            fields={[
              {
                dataKey: 'heartRate',
                color: '#ff7300',
                name: '心拍数',
              },
            ]}
          />
        </div>
      </Card>
    </div>
  )
}

type OProps = {
  deviceId: string
}

const ms = (state: RootState, op: OProps) => ({
  device: selectors.getDeviceById(state, op.deviceId),
  now: getTimestamp(state),
})

const conn = connect(
  ms,
  {},
)

export default conn(DeviceComponent)
