import moment from 'moment'
import { ThunkAction } from '../../types'

import config from '../../config'
import { updateSystem, updateVisibleDevice } from '../../store/System/actions'
import { getDeviceVisibleExists } from '../../store/System/selectors'

export function dataLoadingStart(): ThunkAction {
  return (dispatch) => {
    dispatch(
      updateSystem({
        loadingData: true,
      }),
    )
  }
}

export function dataLoadingEnd(): ThunkAction {
  return (dispatch) => {
    dispatch(
      updateSystem({
        loadingData: false,
      }),
    )
  }
}

export function registerDeviceId(id: string): ThunkAction {
  return (dispatch, getState) => {
    if (getDeviceVisibleExists(getState(), id)) {
      return
    }
    dispatch(setVisibleDeviceId(id))
  }
}

export const setInvisibleDeviceId = (id: string) =>
  updateVisibleDevice({ id, visible: false })

export const setVisibleDeviceId = (id: string) =>
  updateVisibleDevice({ id, visible: true })

export function startTimer(): ThunkAction {
  return (dispatch) => {
    dispatch(updateTimer())
    setInterval(() => {
      dispatch(updateTimer())
    }, config.clockIntervalMs)
  }
}

export function updateTimer(): ThunkAction {
  return (dispatch) => {
    const timestamp = Date.now()
    const m = moment(timestamp)
    const timestampStr = m.format('YYYY-MM-DD HH:mm:ss')

    dispatch(
      updateSystem({
        timestamp,
        timestampStr,
      }),
    )
  }
}
