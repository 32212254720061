import { Button, Paper, Tab, Tabs } from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'

import { Page } from '../../components'
import { logout } from '../Firebase/operations'
import { startTimer } from '../System/operations'
import UserForm from '../UserForm'
import HistoryPane from '../HistoryPane'
import DeviceList from './DeviceList'
import DeviceVisibleButtons from './DeviceVisibleButtons'
import { loadData } from './operations'
import HistoryForm from './HistoryForm'

type Props = {
  loadData: () => void
  logout: () => void
  startTimer: () => void
}

type State = {
  selectedTab: number
}

class TopContainer extends React.Component<Props, State> {
  state = { selectedTab: 0 }
  componentDidMount() {
    this.props.loadData()
    this.props.startTimer()
  }

  render() {
    const { props } = this

    return (
      <div>
        <Page>
          <Tabs
            value={this.state.selectedTab}
            onChange={(e, selectedTab: number) => {
              this.setState({ selectedTab })
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="ホーム" />
            <Tab label="履歴" />
            <Tab label="設定" />
          </Tabs>
          <SwipeableViews
            axis={'x'}
            index={this.state.selectedTab}
            onChangeIndex={(selectedTab: number) => {
              this.setState({ selectedTab })
            }}
          >
            <div>
              <DeviceVisibleButtons />
              <DeviceList />
            </div>
            <div>
              <DeviceVisibleButtons />
              <HistoryForm />
              <HistoryPane />
            </div>
            <div>
              <Paper>
                <UserForm />
              </Paper>
              <Paper>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => props.logout()}
                >
                  ログアウトする
                </Button>
              </Paper>
            </div>
          </SwipeableViews>
        </Page>
      </div>
    )
  }
}

const ms = () => ({})

const conn = connect(ms, { loadData, logout, startTimer })

export default conn(TopContainer)
