import { connect } from 'react-redux'
import { updateLoginField } from '../../store/LoginForm/actions'
import {
  getLoginField,
  getLoginFieldError,
} from '../../store/LoginForm/selectors'
import { FieldError, LoginField, State } from '../../types'
import { passwordLogin } from '../Firebase/operations'
import LoginForm, { Props } from './components/LoginForm'

type OProps = {}
type SProps = {
  field: LoginField
  error: FieldError
}
type DProps = {
  passwordLogin: (email: string, password: string) => void
  updateLoginField: (field: Partial<LoginField>) => void
}

const conn = connect<SProps, DProps, OProps, Props, State>(
  state => ({
    field: getLoginField(state),
    error: getLoginFieldError(state),
  }),
  { passwordLogin, updateLoginField },
  (sp, dp) => ({
    ...sp,
    onChangeField: dp.updateLoginField,
    onSubmit: () => dp.passwordLogin(sp.field.email, sp.field.password),
  }),
)

export default conn(LoginForm)
