import { applyMiddleware, compose, createStore } from 'redux'
import { PersistConfig, persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import reducer from './reducer'
import { State } from './types'

const persistConfig: PersistConfig<State> = {
  key: 'root',
  storage,
  whitelist: ['System'],
}

export default () => {
  const middleware = [thunk]
  const enhancers = [] as unknown[]

  // @ts-ignore
  const devtool = (window as unknown).__REDUX_DEVTOOLS_EXTENSION__

  if (process.env.NODE_ENV === 'development') {
    if (typeof devtool === 'function') {
      enhancers.push(devtool())
    }
  }

  const composer = compose(
    applyMiddleware(...middleware),
    // @ts-ignore
    ...enhancers,
  )

  const persistedReducer = persistReducer(persistConfig, reducer)
  const store = createStore(persistedReducer, composer)
  const persistor = persistStore(store)

  return { store, persistor }
}
