import _ from 'lodash'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DeviceLog, DeviceStateLog } from '../../types'
import * as actions from './actions'

export type State = {
  [id: string]: {
    logs: DeviceLog[]
    stateLogs: DeviceStateLog[]
  }
}

export const initialState: State = {}

export default reducerWithInitialState<State>(initialState)
  .case(
    actions.receiveDeviceStateLog,
    (state, { deviceId, stateLog, filterTime }) => {
      const newStateLogs = [
        ..._.get(state, [deviceId, 'stateLogs'], []),
        stateLog,
      ].filter(v => v.timestamp >= filterTime)

      return _.merge({}, state, {
        [deviceId]: { stateLogs: _.sortBy(newStateLogs, 'timestamp') },
      })
    },
  )
  .case(actions.receiveDeviceLog, (state, { log, filterTime, deviceId }) => {
    const newLogs = [..._.get(state, [deviceId, 'logs'], []), log].filter(
      v => v.timestamp >= filterTime,
    )

    return _.merge({}, state, {
      [deviceId]: { logs: _.sortBy(newLogs, 'timestamp') },
    })
  })
