import { actionCreatorFactory } from 'typescript-fsa'
import { System } from '../../types'

const actionCreator = actionCreatorFactory()

export const updateSystem = actionCreator<Partial<System>>('updateSystem')
export const updateVisibleDevice = actionCreator<{
  id: string
  visible: boolean
}>('updateVisibleDevice')
