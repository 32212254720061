import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DeviceLog, DeviceStateLog } from '../../types'
import * as actions from './actions'

export type HistoryLog = {
  error: boolean
  logs: DeviceLog[]
  stateLogs: DeviceStateLog[]
}

export type State = {
  [id: string]: HistoryLog | undefined
}

const initialState: State = {}

export default reducerWithInitialState<State>(initialState)
  .case(actions.saveHistoryLogs, (state, { deviceId, logs, stateLogs }) => {
    const current = state[deviceId]

    return {
      ...state,
      [deviceId]: {
        error: false,
        logs: logs || (current ? current.logs : []),
        stateLogs: stateLogs || (current ? current.stateLogs : []),
      },
    }
  })
  .case(actions.manyErrorHistoryLogs, (state, { deviceId }) => {
    return {
      ...state,
      [deviceId]: { error: true, logs: [], stateLogs: [] },
    }
  })
