import moment from 'moment'
import _ from 'lodash'
import { ThunkAction, DeviceLogRaw } from '../../types'
import { loadLogs, loadStateLogs } from '../Firebase/operations'

export function downloadCSV(deviceId: string, day: string): ThunkAction {
  return async () => {
    const logs = await loadLogs(deviceId, day)

    download(makeCSV(logs), `sheetmonitor_${deviceId}_${day}`)
    const stateLogs = await loadStateLogs(deviceId, day)

    download(makeCSV(stateLogs), `sheetmonitor_${deviceId}_${day}_states`)
  }
}

export function makeCSV(logs: DeviceLogRaw[]): string {
  const log = _.first(logs)

  if (!log) {
    return 'noLog'
  }
  const head = ['timestamp', 'timestampStr', ...Object.keys(log.fields)]

  const toLine = (log: DeviceLogRaw) => {
    return [
      log.timestamp,
      moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      ..._.values(log.fields),
    ].map(String)
  }
  const cells = [head, ...logs.map(toLine)]

  return cells.map(vs => vs.join(',')).join('\n')
}

export function download(text: string, name: string) {
  const href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  const a = document.createElement('a')

  a.setAttribute('href', href)
  a.setAttribute('download', name + '.csv')
  document.body && document.body.appendChild(a)
  a.click()
  a.remove()
}
