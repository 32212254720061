import { ThunkAction } from '../../types'
import { requestData } from '../Firebase/operations'
import { dataLoadingEnd, dataLoadingStart } from '../System/operations'

export function loadData(): ThunkAction {
  return async dispatch => {
    dispatch(dataLoadingStart())
    await dispatch(requestData())
    dispatch(dataLoadingEnd())
  }
}
