import _ from 'lodash'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { System } from '../../types'
import * as actions from './actions'

export type State = System

const initialState = {
  loadingData: false,
  timestamp: 0,
  timestampStr: '---',
  deviceVisibles: {},
} as State

export default reducerWithInitialState(initialState)
  .case(actions.updateSystem, (state, system) => {
    return { ...state, ...system }
  })
  .case(actions.updateVisibleDevice, (state, payload) => {
    return _.merge({}, state, {
      deviceVisibles: { [payload.id]: payload.visible },
    })
  })
