import * as React from 'react'
import { connect } from 'react-redux'
import NavBar from '../../components/NavBar'
import { getIsLogin } from '../../store/Auth/selectors'
import { State } from '../../types'
import { refInit } from '../Firebase/operations'

type Props = {
  isLogin: boolean
  refInit: () => void
}

class NavBarContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.refInit()
  }
  render() {
    return <NavBar {...this.props} />
  }
}

const ms = (state: State) => ({
  isLogin: getIsLogin(state),
})

const conn = connect(ms, { refInit })

export default conn(NavBarContainer)
