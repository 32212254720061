import moment from 'moment'
import {
  DeviceLog,
  DeviceLogRaw,
  DeviceStateLog,
  DeviceStateLogRaw,
  ThunkAction,
} from '../../types'
import { receiveDeviceLog, receiveDeviceStateLog } from './actions'

export function saveDeviceStateLogs(
  deviceId: string,
  logRaw: DeviceStateLogRaw,
): ThunkAction {
  return async (dispatch) => {
    const stateLog: DeviceStateLog = {
      timestamp: logRaw.timestamp,
      timestampStr: moment(logRaw.timestamp).format('HH:mm:ss'),
      ...logRaw.fields,
    }
    const filterTime = moment().add(-30, 'minutes').unix() * 1000

    dispatch(receiveDeviceStateLog({ deviceId, stateLog, filterTime }))
  }
}

export function saveDeviceLogs(
  deviceId: string,
  logRaw: DeviceLogRaw,
): ThunkAction {
  return async (dispatch) => {
    const log: DeviceLog = {
      timestamp: logRaw.timestamp,
      timestampStr: moment(logRaw.timestamp).format('HH:mm:ss'),
      ...logRaw.fields,
    }
    const filterTime = moment().add(-30, 'minutes').unix() * 1000

    dispatch(receiveDeviceLog({ deviceId, log, filterTime }))
  }
}
