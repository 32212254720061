import { actionCreatorFactory } from 'typescript-fsa'
import { DeviceLog, DeviceStateLog } from '../../types'

const actionCreator = actionCreatorFactory()

export const saveHistoryLogs = actionCreator<{
  deviceId: string
  logs?: DeviceLog[]
  stateLogs?: DeviceStateLog[]
}>('saveHistoryLogs')

export const manyErrorHistoryLogs = actionCreator<{
  deviceId: string
}>('manyErrorHistoryLogs')
